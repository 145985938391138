<template>
 <div :class="windowClass">
   <v-container>
      <v-row no-gutters>
          
                          <v-text-field
                             v-model="game"
                                label="game"
                                outlined
                                dense
                            />
                            <v-spacer/>
                            <v-text-field
                             type="number"
                             v-model="draw_id"
                                label="draw id"
                                outlined
                                dense
                            ></v-text-field>
                              <v-spacer/>
                            <v-text-field
                              v-model="draw_date"
                                label="draw date"
                                outlined
                                dense
                            ></v-text-field>

                             <v-spacer/>
                            <v-text-field
                             type="number"
                             v-model="loop"
                                label="Loop Times"
                                outlined
                                dense
                            ></v-text-field>

                            <v-spacer/>
                            <v-btn color="primary" :loading="loading" @click="loading=true,runTest()">Run</v-btn>

      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <div v-for="(data, i) in responseData" :key="i"><small>{{data}}</small></div>
        </v-col>
      </v-row>
   </v-container>
  </div>
</template>
<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
import {
  mapMutations
} from 'vuex'
  export default {
    mixins: [API_MIXINS],
    data: () => ({
      loop: 10,
      selected_game: {},
      loading: false,
      draw_id:0,
      game: '',
      draw_date:'Mar-23 08:00 PM',
      data_open_draws:[],
      responseData:[]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getOpenDaws()
        } 
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      }
      
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      setGame() {
        this.game = this.selected_game.game
        this.draw_id = this.selected_game.id
        this.loop = Math.floor(Math.random() * 100)
          this.draw_date = this.$moment(this.selected_game.draw_datetime).format('MMM-DD hh:mm A')
        this.responseData=[]
      },
      runTest(){
          setTimeout( ()=> {    //  call a 3s setTimeout when the loop is called
             // console.log("hello",  this.loop)
              this.newTicket(this.loop)
              this.loop--;                     //  increment the counter
              if ( this.loop > 0) {            //  if the counter < 10, call the loop function
                this.runTest();             //  ..  again which will trigger another 
              } else {
                this.loading = false
              }                       //  ..  setTimeout()
          }, 100)
      },
       getOpenDaws() {
          this.$http.get("report/get_open_draws").then(response => {
            response.data.draws != null?this.data_open_draws = response.data.draws:this.data_open_draws =[]
            console.log(response.data.draws)
       }).catch(e => {
            console.log(e)
        })
      },
      newTicket() {
          var amount = Math.floor((Math.random() +1) * 50).toString()
          var param = {}
            param.Lines = this.genLine(amount)
            param.draw_id = parseInt(this.draw_id)
            param.amount = amount
            param.game = this.game
            param.draw_date = this.draw_date
          this.$http.post("ticket/new",param).then(response => {
            response.data.status?this.responseData.unshift(response.data.message + "-" + response.data.ticket.id):this.responseData.unshift(response.data.message)
          }).catch(e => {
            console.log(e.data)
          })
      },
      genLine(amount){
          var line = []
          if(this.game == '2D' || this.game == 'SWER2' || this.game == 'STLPARES' || this.game == 'STLLOCAL' || this.game == '2DLOCAL') {
              line =  [{num1: Math.floor(Math.random() * 10), num2: Math.floor(Math.random() * 10), line_type: "STD", total_amount: amount, line_amount: amount}]
          } else if(this.game == '3D' || this.game == '3DLOCAL' || this.game == 'PICK3' || this.game == 'SWER3') {
              line =  [{num1: Math.floor(Math.random() * 10), num2: Math.floor(Math.random() * 10), num3: Math.floor(Math.random() * 10), line_type: "STD", total_amount: amount, line_amount: amount}]
          }  else {
              line =  [{num1: Math.floor(Math.random() * 10), num2: Math.floor(Math.random() * 10), num3: Math.floor(Math.random() * 10), num4: Math.floor(Math.random() * 10), line_type: "STD", total_amount: amount, line_amount: amount}]
          }
          
          return line
      }
      
    },
  }
</script>